"use client"

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, EffectFade } from 'swiper/modules';
import Image from 'next/image';
import { memo, useCallback } from 'react';

interface AuthImagesSliderProps {
    images: string[];
    spaceBetween?: number;
    effect?: string;
    navigation?: boolean;
    paginationClickable?: boolean;
    autoplayDelay?: number;
    className?: string;
}

const AuthImagesSlider = memo(({
    images = [],
    spaceBetween = 30,
    effect = 'fade',
    navigation = true,
    paginationClickable = true,
    autoplayDelay = 4500,
    className = 'mySwiper',
}: AuthImagesSliderProps) => {
    const renderSlide = useCallback((src: string, index: number) => (
        <SwiperSlide className='h-[100%]' key={index}>
            <Image 
                src={src} 
                width={1800} 
                height={1800} 
                className="w-[100%] h-[100%] object-cover" 
                alt={`Slide image ${index + 1}`}
                priority={index === 0}
                loading={index === 0 ? 'eager' : 'lazy'}
            />
        </SwiperSlide>
    ), []);

    return (
        <Swiper
            spaceBetween={spaceBetween}
            effect={effect}
            navigation={navigation}
            pagination={{
                clickable: paginationClickable,
            }}
            autoplay={{
                delay: autoplayDelay,
                disableOnInteraction: false,
            }}
            modules={[Autoplay, EffectFade]}
            className={className}
        >
            {images.map(renderSlide)}
        </Swiper>
    );
});

AuthImagesSlider.displayName = 'AuthImagesSlider';

export default AuthImagesSlider; 